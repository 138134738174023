<template>
    <div class="content-wrapper">
        <PageHeader screenName="Ver doenças" :linkItems="linkItems"/>

        <ConfirmAtivarDeleteDoencaModal
            :modalShow="modal.showModal"
            :typeModal="modal.typeModal"
            @confirmed="changeStateDoenca"
            @canceled="cancelarDesativarDoenca"
        />

        <TimedAlert variant="danger" :message="alert.errorMessage" :show="alert.show" />

        <main class="card hidden_overflow_tabela">
            <div v-if="canInsert" class="pt-2 px-2">
                <b-button @click="redirectToRegister()" variant="primary-button">
                    <feather-icon
                        icon="PlusIcon"
                        class="mr-50"   
                    />
                    <span class="align-middle">Adicionar nova doença</span>
                </b-button>
            </div>
            <hr>
            <TableFilter
                :filter="filter"
                @filterTable="loadDataDoenca"
            />
            <div v-if="loadingData" class="d-flex justify-content-center my-3">
                <div class="spinner-border text-custom-blue" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <section class="list-doenca" v-if="!loadingData">
                <b-table
                    id="tableDoenca"
                    responsive
                    :stickyColumn="true"
                    :busy.sync="filtering"
                    :fields="table.fields"
                    :items="table.items"
                    @sort-changed="ordenarColuna"
                    no-local-sorting
                    :no-sort-reset="true"
                >
                    <template #cell(nome_tecnico_doenca)="row">
                        <span class="font-weight-bold comprimento_maximo_celulas"
                        v-b-tooltip
                        :title="row.item.nome_tecnico_doenca.length > lengthNomeTecnicoMaximo ? row.item.nome_tecnico_doenca : null">
                        {{ row.item.nome_tecnico_doenca }}
                        </span>
                    </template>
                    <template #cell(vacinas)="row">
                        <vue-perfect-scrollbar class="altura_max_vacinas_scrollbar" :settings="settings">
                            <div class="d-flex flex-wrap">
                                <div 
                                v-for="vacina in row.item.vacinas"
                                :key="vacina.nome"
                                class="rounded vacinas"
                                >
                                    {{ vacina.nome_tecnico }}
                                </div>
                            </div>
                        </vue-perfect-scrollbar>
                    </template>
                    <template #cell(ativo)="row">
                        <TableSwitch
                            :disabled="!canEdit"
                            :checked="row.item.ativo" 
                            :description="row.item.nome_tecnico_doenca"
                            :itemId="row.item.id_doenca"
                            @stateModified="openModalConfirm"
                        />
                    </template>
                    <template #cell(actions)="row">
                        <feather-icon
                            @click.prevent="redirectToShow(row.item)"
                            id="view-doenca"
                            role="button"
                            class="text-custom-blue mr-1"
                            icon="EyeIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Detalhes da Doença</b>"
                        />
                        <feather-icon
                            v-if="canEdit"
                            @click.prevent="redirectToEdit(row.item)"
                            id="edit-doenca"
                            role="button"
                            class="text-custom-blue"
                            icon="EditIcon"
                            size="18"
                            v-b-tooltip.hover.html 
                            title="<b>Editar Doença</b>"
                        />
                    </template>
                </b-table>
                <div class="pb-2 px-1">
                    <span v-if="withoutDoenca" class="ml-1">Nenhuma doença encontrada</span>
                    <b-col sm="12" class="mt-2">
                        <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage"/>
                    </b-col>
                </div>
            </section>
        </main>
    </div>
</template>

<script>
import {actions, subjects} from "@/libs/acl/rules";
import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, VBTooltip } from 'bootstrap-vue'
import vSelect from 'vue-select'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import PageHeader from '@/views/components/custom/page-header/PageHeader.vue'
import TableSwitch from '@/views/components/custom/table-switch/TableSwitch.vue'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue'
import ConfirmAtivarDeleteDoencaModal from '@/views/components/custom/modals/ConfirmAtivarDeleteDoencaModal.vue'
import TimedAlert from '@/views/components/custom/timed-alert/TimedAlert.vue'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
    title: 'Doenças',

    components: {
        BForm, BCol, BRow, BFormGroup, BFormInput,
        BButton, BTable, TableSwitch, TableFilter,
        vSelect, CustomPagination, PageHeader, 
        ConfirmAtivarDeleteDoencaModal, TimedAlert, VuePerfectScrollbar
    },

    directives: {
        'b-tooltip': VBTooltip
    },

    data() {
        return {
            alert: {
                errorMessage: '',
                show: false
            },
            settings: {
                maxScrollbarLength: 70,
                wheelSpeed: 0.2,
                wheelPropagation: false
            },
            linkItems: [
                {
                    name: 'Doenças',
                    active: true
                }
            ],
            timeID: null,
            filtering: false,
            filter: false,
            loadingData: true,
            withoutDoenca: false, 
            canEdit: false,
            canInsert: false,
            table: {
                fields: [
                    {key: "nome_tecnico_doenca", label: "DOENÇAS", sortable: true, class: 'comprimento_maximo_tabela_header'},
                    {key: "vacinas", label: "VACINAS RELACIONADAS", sortable: false, class: 'mw-300'},
                    {key: "ativo", label: "STATUS", sortable: true, class: 'comprimento_status'},
                    {key: "actions", label: "AÇÕES", sortable: false, class: 'mw-150 text-center col-1'}
                ],
                items: [],
                optionsSize: [10, 25, 50, 100],
                columnOrder: 'nome_tecnico_doenca',
                order: 'asc'
            },
            paginationData: {
                currentPage: 0,
                totalLines: 0,
                fromLine: 0,
                toLine: 0,
                defaultSize: 10,
            },
            itemDesativar: null,
            modal: {
                showModal: false,
                typeModal: 'success',
            },
            lengthNomeTecnicoMaximo: 28
        }
    },
    mounted() {
        this.canEdit = this.verifyPermissions(actions.ATUALIZAR)
        this.canInsert = this.verifyPermissions(actions.INSERIR)
    },
    methods: {
        loadDataDoenca(filterData = null) {
            this.paginationData.defaultSize = filterData ? filterData.defaultSize : 10;

            if(filterData.hasOwnProperty('currentPage')) { this.paginationData.currentPage = filterData.currentPage; }

            const parameters = {
                termoPesquisa: filterData ? filterData.searchTerm : '',
                tamanho: this.paginationData.defaultSize,
                pagina: this.paginationData.currentPage,
                colunaOrdenar: this.table.columnOrder,
                ordem: this.table.order
            };

            this.filtering = true;

            this.$http.get(this.$api.saveAndGetDoenca(), { params: parameters }).then(({ data }) => {
                this.table.items = data.data;

                this.withoutDoenca = false;
                if(data.data.length == 0) {
                    this.withoutDoenca = true;
                }

                this.loadingData = false;

                this.filtering = false;
                this.startPagination(data)
            })
        },

        startPagination(data) {
            this.paginationData.currentPage = data.current_page;
            this.paginationData.totalLines = data.total;
            this.paginationData.fromLine = data.from;
            this.paginationData.toLine = data.to;
        },

        ordenarColuna(sort) {
            this.table.columnOrder = sort.sortBy;
            this.table.order = sort.sortDesc ? 'desc' : 'asc';

            this.filtering = true;
            this.filter = !this.filter;
        },

        updateCurrentPage(page) {
            this.paginationData.currentPage = page;
            this.filtering = true;
            this.filter = !this.filter;
        },

        redirectToEdit({id_doenca}) {
            this.$router.push(
                { 
                    name: 'doenca-edit', 
                    params: {
                        idDoenca: id_doenca
                    }
                }
            );
        },

        redirectToShow({id_doenca, nome_tecnico_doenca, nome_popular, descricao,  vacinas}) {
            this.$router.push(
                { 
                    name: 'doenca-show', 
                    params: {
                        idDoenca: id_doenca, 
                        nomeTecnicoDoenca: nome_tecnico_doenca,
                        nomePopular: nome_popular,
                        descricao: descricao,
                        vacinasSelecionadas : vacinas
                    }
                }
            );
        },

        openModalConfirm(values) {
            this.modal.typeModal = 'warning';

            if(values.state) {
                this.modal.typeModal = 'success';
            }

            this.$helpers.changeStateActiveTableRow(this.table.items, values, 'id_doenca');

            this.itemDesativar = values;
            this.modal.showModal = true;
        },

        changeStateDoenca() {
            this.$http.patch(this.$api.doencaWithParameter(this.itemDesativar.itemId)).then(() => {
                this.modal.showModal = false;
            }).catch(({ response: { data, status } }) => {
                this.modal.showModal = false;
                this.$helpers.backStateActiveTableRow(this.table.items, this.itemDesativar, 'id_doenca');
                if(status == 400){
                    this.modalDoencaDesativacao();
                }else{
                    this.alert.errorMessage = data.errors;
                    this.alert.show = !this.alert.show;
                }
            });
        },

        cancelarDesativarDoenca() {
            this.$helpers.backStateActiveTableRow(this.table.items, this.itemDesativar, 'id_doenca');

            this.modal.showModal = false;
        },

        redirectToRegister() {
            this.$router.push({name: 'doenca-register'});
        },

        modalDoencaDesativacao() 
        {
            this.$swal({
                title: 'Erro',
                html: '<div>Para desativar doença é necessário que as vacinas vinculadas a ela estejam desativadas.</div><div>Tente novamente.</div>',
                icon: 'warning',
                confirmButtonText: 'Ok',
                customClass: {
                confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
            })
        },
        verifyPermissions(action) {
            const permission = this.$can(actions[action], subjects.DOENCA)

            return [permission].includes(true);
        }
    }
}
</script>

<style>
.label_size_doenca{
    font-size: 15px;
}
.vacinas{
    background-color: #2772C0;
    color: #ffffff;
    padding: 5px;
    margin-right: 10px;
    margin-bottom: 5px;
    font-weight: 400;
}
.altura_max_vacinas_scrollbar{
    max-height:160px;
    padding-right: 15px;
}
.comprimento_maximo_celulas{
  max-width: 240px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  white-space: nowrap;
}
.comprimento_maximo_tabela_header{
  width: 160px;
}
.comprimento_status{
    width: 100px;
}
.hidden_overflow_tabela{
    overflow: hidden;
}
</style>